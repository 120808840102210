
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as createDqKSNjSdbTMeta } from "/home/runner/work/mito-ui/mito-ui/pages/cpmm/create.vue?macro=true";
import { default as envBJH7jl2EJxMeta } from "/home/runner/work/mito-ui/mito-ui/pages/env.vue?macro=true";
import { default as indexFh6cnRFiIqMeta } from "/home/runner/work/mito-ui/mito-ui/pages/index.vue?macro=true";
import { default as indexJdzhCdinmLMeta } from "/home/runner/work/mito-ui/mito-ui/pages/launchpad/[ido]/index.vue?macro=true";
import { default as resultRRpjHYreXSMeta } from "/home/runner/work/mito-ui/mito-ui/pages/launchpad/[ido]/result.vue?macro=true";
import { default as subscriberssoDdoUYHOKMeta } from "/home/runner/work/mito-ui/mito-ui/pages/launchpad/[ido]/subscribers.vue?macro=true";
import { default as subscriptionioTEXPK0GxMeta } from "/home/runner/work/mito-ui/mito-ui/pages/launchpad/[ido]/subscription.vue?macro=true";
import { default as whitelistbdzzFgL5Z7Meta } from "/home/runner/work/mito-ui/mito-ui/pages/launchpad/[ido]/whitelist.vue?macro=true";
import { default as _91ido_93ReV018EK4iMeta } from "/home/runner/work/mito-ui/mito-ui/pages/launchpad/[ido].vue?macro=true";
import { default as index25RdzrIlFDMeta } from "/home/runner/work/mito-ui/mito-ui/pages/launchpad/index.vue?macro=true";
import { default as maintenanceIOIGQa8lcyMeta } from "/home/runner/work/mito-ui/mito-ui/pages/maintenance.vue?macro=true";
import { default as gaugeIjUExBVNXpMeta } from "/home/runner/work/mito-ui/mito-ui/pages/manage/gauge.vue?macro=true";
import { default as indexfG1mmM3v78Meta } from "/home/runner/work/mito-ui/mito-ui/pages/manage/index.vue?macro=true";
import { default as launchpadPaHLE04wqfMeta } from "/home/runner/work/mito-ui/mito-ui/pages/manage/launchpad.vue?macro=true";
import { default as marketubPcB5v6nxMeta } from "/home/runner/work/mito-ui/mito-ui/pages/manage/market.vue?macro=true";
import { default as projectszxMFXXB9uYMeta } from "/home/runner/work/mito-ui/mito-ui/pages/manage/projects.vue?macro=true";
import { default as manageKFjP0Al8vdMeta } from "/home/runner/work/mito-ui/mito-ui/pages/manage.vue?macro=true";
import { default as indexyYobjLSMA3Meta } from "/home/runner/work/mito-ui/mito-ui/pages/missions/index.vue?macro=true";
import { default as leaderboardZE9iKy7tizMeta } from "/home/runner/work/mito-ui/mito-ui/pages/missions/leaderboard.vue?macro=true";
import { default as progressujDixFkVlOMeta } from "/home/runner/work/mito-ui/mito-ui/pages/missions/progress.vue?macro=true";
import { default as missions9R0uupWo0xMeta } from "/home/runner/work/mito-ui/mito-ui/pages/missions.vue?macro=true";
import { default as indexDtPVNFcxgBMeta } from "/home/runner/work/mito-ui/mito-ui/pages/portfolio/activities/index.vue?macro=true";
import { default as rewardso6BVG1evuNMeta } from "/home/runner/work/mito-ui/mito-ui/pages/portfolio/activities/rewards.vue?macro=true";
import { default as swap_45historymHvw3fQ4sMMeta } from "/home/runner/work/mito-ui/mito-ui/pages/portfolio/activities/swap-history.vue?macro=true";
import { default as activitiesWfBUwYlFCIMeta } from "/home/runner/work/mito-ui/mito-ui/pages/portfolio/activities.vue?macro=true";
import { default as indexdZP9LaDkXGMeta } from "/home/runner/work/mito-ui/mito-ui/pages/portfolio/index.vue?macro=true";
import { default as portfolioJdIBCArFP7Meta } from "/home/runner/work/mito-ui/mito-ui/pages/portfolio.vue?macro=true";
import { default as privacy_45policyEgBCeUBoFZMeta } from "/home/runner/work/mito-ui/mito-ui/pages/privacy-policy.vue?macro=true";
import { default as rewardsEIXwzBB7QUMeta } from "/home/runner/work/mito-ui/mito-ui/pages/rewards.vue?macro=true";
import { default as allocator79qRBpUZKgMeta } from "/home/runner/work/mito-ui/mito-ui/pages/settings/allocator.vue?macro=true";
import { default as cpmm_45allowanceYTq3aWr2aSMeta } from "/home/runner/work/mito-ui/mito-ui/pages/settings/cpmm-allowance.vue?macro=true";
import { default as indexc3LWqifgugMeta } from "/home/runner/work/mito-ui/mito-ui/pages/settings/index.vue?macro=true";
import { default as staking2JewOjrXivMeta } from "/home/runner/work/mito-ui/mito-ui/pages/settings/staking.vue?macro=true";
import { default as settingsMGbhl0UPHfMeta } from "/home/runner/work/mito-ui/mito-ui/pages/settings.vue?macro=true";
import { default as swapVtzJmUansTMeta } from "/home/runner/work/mito-ui/mito-ui/pages/swap.vue?macro=true";
import { default as terms_45of_45usehx2eznzJ2XMeta } from "/home/runner/work/mito-ui/mito-ui/pages/terms-of-use.vue?macro=true";
import { default as indexpF8ABt5u8pMeta } from "/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/activities/index.vue?macro=true";
import { default as orderskeWZZCdlS2Meta } from "/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/activities/orders.vue?macro=true";
import { default as tradesTOpb7OcKVEMeta } from "/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/activities/trades.vue?macro=true";
import { default as activitiesbMmVRl76EGMeta } from "/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/activities.vue?macro=true";
import { default as configWFtXSD4BHnMeta } from "/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/config.vue?macro=true";
import { default as indexxa5GDmqR5MMeta } from "/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/index.vue?macro=true";
import { default as infoqPTs7pfyMUMeta } from "/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/info.vue?macro=true";
import { default as liquiditySfygVpRT3jMeta } from "/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/liquidity.vue?macro=true";
import { default as rewardsNWgg56bsQvMeta } from "/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/rewards.vue?macro=true";
import { default as _91vault_93Zyhdp5xZoLMeta } from "/home/runner/work/mito-ui/mito-ui/pages/vault/[vault].vue?macro=true";
import { default as vaultsHQkZXqv33hMeta } from "/home/runner/work/mito-ui/mito-ui/pages/vaults.vue?macro=true";
import { default as voteSZ1RsNWEJoMeta } from "/home/runner/work/mito-ui/mito-ui/pages/vote.vue?macro=true";
export default [
  {
    name: "cpmm-create",
    path: "/cpmm/create",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/cpmm/create.vue")
  },
  {
    name: "env",
    path: "/env",
    meta: envBJH7jl2EJxMeta || {},
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/env.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexFh6cnRFiIqMeta || {},
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/index.vue")
  },
  {
    name: _91ido_93ReV018EK4iMeta?.name,
    path: "/launchpad/:ido()",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/launchpad/[ido].vue"),
    children: [
  {
    name: "launchpad-ido",
    path: "",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/launchpad/[ido]/index.vue")
  },
  {
    name: "launchpad-ido-result",
    path: "result",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/launchpad/[ido]/result.vue")
  },
  {
    name: "launchpad-ido-subscribers",
    path: "subscribers",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/launchpad/[ido]/subscribers.vue")
  },
  {
    name: "launchpad-ido-subscription",
    path: "subscription",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/launchpad/[ido]/subscription.vue")
  },
  {
    name: "launchpad-ido-whitelist",
    path: "whitelist",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/launchpad/[ido]/whitelist.vue")
  }
]
  },
  {
    name: "launchpad",
    path: "/launchpad",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/launchpad/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceIOIGQa8lcyMeta || {},
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/maintenance.vue")
  },
  {
    name: manageKFjP0Al8vdMeta?.name,
    path: "/manage",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/manage.vue"),
    children: [
  {
    name: "manage-gauge",
    path: "gauge",
    meta: gaugeIjUExBVNXpMeta || {},
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/manage/gauge.vue")
  },
  {
    name: "manage",
    path: "",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/manage/index.vue")
  },
  {
    name: "manage-launchpad",
    path: "launchpad",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/manage/launchpad.vue")
  },
  {
    name: "manage-market",
    path: "market",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/manage/market.vue")
  },
  {
    name: "manage-projects",
    path: "projects",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/manage/projects.vue")
  }
]
  },
  {
    name: missions9R0uupWo0xMeta?.name,
    path: "/missions",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/missions.vue"),
    children: [
  {
    name: "missions",
    path: "",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/missions/index.vue")
  },
  {
    name: "missions-leaderboard",
    path: "leaderboard",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/missions/leaderboard.vue")
  },
  {
    name: "missions-progress",
    path: "progress",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/missions/progress.vue")
  }
]
  },
  {
    name: portfolioJdIBCArFP7Meta?.name,
    path: "/portfolio",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/portfolio.vue"),
    children: [
  {
    name: activitiesWfBUwYlFCIMeta?.name,
    path: "activities",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/portfolio/activities.vue"),
    children: [
  {
    name: "portfolio-activities",
    path: "",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/portfolio/activities/index.vue")
  },
  {
    name: "portfolio-activities-rewards",
    path: "rewards",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/portfolio/activities/rewards.vue")
  },
  {
    name: "portfolio-activities-swap-history",
    path: "swap-history",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/portfolio/activities/swap-history.vue")
  }
]
  },
  {
    name: "portfolio",
    path: "",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/portfolio/index.vue")
  }
]
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyEgBCeUBoFZMeta || {},
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/privacy-policy.vue")
  },
  {
    name: "rewards",
    path: "/rewards",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/rewards.vue")
  },
  {
    name: settingsMGbhl0UPHfMeta?.name,
    path: "/settings",
    meta: settingsMGbhl0UPHfMeta || {},
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/settings.vue"),
    children: [
  {
    name: "settings-allocator",
    path: "allocator",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/settings/allocator.vue")
  },
  {
    name: "settings-cpmm-allowance",
    path: "cpmm-allowance",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/settings/cpmm-allowance.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/settings/index.vue")
  },
  {
    name: "settings-staking",
    path: "staking",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/settings/staking.vue")
  }
]
  },
  {
    name: "swap",
    path: "/swap",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/swap.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    meta: terms_45of_45usehx2eznzJ2XMeta || {},
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/terms-of-use.vue")
  },
  {
    name: _91vault_93Zyhdp5xZoLMeta?.name,
    path: "/vault/:vault()",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vault/[vault].vue"),
    children: [
  {
    name: activitiesbMmVRl76EGMeta?.name,
    path: "activities",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/activities.vue"),
    children: [
  {
    name: "vault-vault-activities",
    path: "",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/activities/index.vue")
  },
  {
    name: "vault-vault-activities-orders",
    path: "orders",
    meta: orderskeWZZCdlS2Meta || {},
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/activities/orders.vue")
  },
  {
    name: "vault-vault-activities-trades",
    path: "trades",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/activities/trades.vue")
  }
]
  },
  {
    name: "vault-vault-config",
    path: "config",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/config.vue")
  },
  {
    name: "vault-vault",
    path: "",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/index.vue")
  },
  {
    name: "vault-vault-info",
    path: "info",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/info.vue")
  },
  {
    name: "vault-vault-liquidity",
    path: "liquidity",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/liquidity.vue")
  },
  {
    name: "vault-vault-rewards",
    path: "rewards",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vault/[vault]/rewards.vue")
  }
]
  },
  {
    name: "vaults",
    path: "/vaults",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vaults.vue")
  },
  {
    name: "vote",
    path: "/vote",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vote.vue")
  },
  {
    name: "vaults",
    path: "/vaults",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vaults.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/home/runner/work/mito-ui/mito-ui/pages/vaults.vue")
  }
]